<template>
  <div id="app">
    <NavBar />
    <div>
      <router-view />
    </div>
    <Footer />
  </div>
</template>
<script>
import NavBar from '@/component/Navbar.vue';
import Footer from '@/component/Footer.vue';

export default {
  components: {
    NavBar,
    Footer,
  },
};
</script>

<style>
#app {
  font-family: 'Noto Sans CJK JP';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
