<template>
  <nav class="bg-white shadow-lg fixed w-full z-10">
    <div class="mx-auto max-w-7xl px-2 lg:px-6 lg:px-8">
      <div class="flex lg:h-[80px] h-16 items-center justify-between">
        <img
          class="block h-6 lg:h-8 ml-3 lg:ml-0 w-auto"
          src="@/assets/icons/lixil.png"
          alt="Lixil"
        />
        <!-- desktop -->
        <div class="hidden min-[1281px]:block flex items-center justify-center">
          <!-- <img class="block h-6 ml-3 w-auto" src="@/assets/icons/lixil.png" alt="Lixil" /> -->
          <div class="lg:ml-6">
            <ul v-if="checkRouter()" class="flex space-x-4">
              <li
                @click="top"
                :style="{ color: colorTop }"
                class="text-gray-500 px-3 py-2 rounded-md text-md font-bold"
              >
                HOME
              </li>
              <li
                @click="customer_issues"
                :style="{ color: colorCustomerIssues }"
                class="text-gray-500 px-3 py-2 rounded-md text-md font-bold"
              >
                お客様の課題
              </li>
              <li
                @click="solution"
                :style="{ color: colorSolution }"
                class="text-gray-500 px-3 py-2 rounded-md text-md font-bold"
              >
                ソリューション
              </li>
              <li
                @click="us_choose"
                :style="{ color: colorChoose }"
                class="text-gray-500 px-3 py-2 rounded-md text-md font-bold"
              >
                選ばれる理由
              </li>
              <li
                @click="voice"
                :style="{ color: colorVoice }"
                class="text-gray-500 px-3 py-2 rounded-md text-md font-bold"
              >
                ユーザーの声
              </li>
              <li
                @click="inquiry"
                :style="{ color: colorInquiry }"
                class="text-gray-500 px-3 py-2 rounded-md text-md font-bold"
              >
                運用開始までの流れ
              </li>
            </ul>
            <ul class="flex space-x-4" v-else>
              <li class="text-gray-500 px-3 py-2 rounded-md text-md font-bold">HOME</li>
              <li class="text-gray-500 px-3 py-2 rounded-md text-md font-bold">お客様の課題</li>
              <li class="text-gray-500 px-3 py-2 rounded-md text-md font-bold">ソリューション</li>
              <li class="text-gray-500 px-3 py-2 rounded-md text-md font-bold">選ばれる理由</li>
              <li class="text-gray-500 px-3 py-2 rounded-md text-md font-bold">ユーザーの声</li>
              <li class="text-gray-500 px-3 py-2 rounded-md text-md font-bold">
                運用開始までの流れ
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      mobile: {
        open: false,
      },

      colorTop: '',
      colorVoice: '',
      colorChoose: '',
      colorInquiry: '',
      colorSolution: '',
      colorCustomerIssues: '',
    };
  },
  methods: {
    top() {
      window.scrollTo({ top: 0 });
    },
    customer_issues() {
      window.scrollTo({ top: 830 });
    },
    solution() {
      window.scrollTo({ top: 1950 });
    },
    us_choose() {
      window.scrollTo({ top: 3000 });
    },
    voice() {
      window.scrollTo({ top: 4045 });
    },
    inquiry() {
      window.scrollTo({ top: 5600 });
    },
    checkRouter() {
      return this.$route.path === '/';
    },
  },
  mounted() {
    this.colorTop = '#e75313';
    let isScrolling = '';
    window.addEventListener(
      'scroll',
      () => {
        this.scrolling = true;
        window.clearTimeout(isScrolling);
        isScrolling = setTimeout(() => {
          if (window.scrollY < 720) {
            this.colorTop = '#e75313';
          } else {
            this.colorTop = '';
          }
          if (window.scrollY >= 720 && window.scrollY < 1560) {
            this.colorCustomerIssues = '#e75313';
          } else {
            this.colorCustomerIssues = '';
          }
          if (window.scrollY >= 1560 && window.scrollY < 2850) {
            this.colorSolution = '#e75313';
          } else {
            this.colorSolution = '';
          }
          if (window.scrollY >= 2850 && window.scrollY < 3800) {
            this.colorChoose = '#e75313';
          } else {
            this.colorChoose = '';
          }
          if (window.scrollY >= 3800 && window.scrollY < 5250) {
            this.colorVoice = '#e75313';
          } else {
            this.colorVoice = '';
          }
          if (window.scrollY >= 5250) {
            this.colorInquiry = '#e75313';
          } else {
            this.colorInquiry = '';
          }
          this.scrolling = false;
        }, 100);
      },
      false,
    );
  },
};
</script>

<style scoped>
li {
  cursor: pointer;
}
</style>
