import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_MEASUREMENT_ID,
    params: {
      send_page_view: false,
    },
  },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
