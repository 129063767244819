import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/menu',
    redirect: '/',
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../pages/terms/Terms.vue'),
  },
  {
    path: '/terms/appendix',
    name: 'AppendixTerm',
    component: () => import('../pages/terms/Appendix.vue'),
  },
  {
    path: '/terms/basic',
    name: 'BasicTerm',
    component: () => import('../pages/basic/Basic.vue'),
  },
  {
    path: '/terms/basic/appendix',
    name: 'AppendixBasicTerm',
    component: () => import('../pages/basic/Appendix.vue'),
  },
  {
    path: '/terms/pluscare',
    name: 'PluscareTerm',
    component: () => import('../pages/pluscare/Pluscare.vue'),
  },
  {
    path: '/terms/pluscare/appendix',
    name: 'AppendixPluscareTerm',
    component: () => import('../pages/pluscare/Appendix.vue'),
  },
  {
    path: '/',
    name: 'landing',
    component: () => import('../pages/menu/index.vue'),
  },
];

const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes,
  scrollBehavior() {
    // always scroll to top
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
